import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import React, { createContext, ReactNode, useContext } from 'react';
import ZodForm, { useZodFormFieldArray, ZodNestedForm } from '~/components/zod/ZodForm';
import { FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useTenantWrapper } from '~/tenants/common/TenantContextProvider';
import z from 'zod';
import Big from 'big.js';
import { State, useHookstate } from '@hookstate/core';

interface PerformableGroupState {
  revenue: Big;
  added: TenantPerformableConfig[];
}

interface PerformableGroupContext {
  required: TenantPerformableConfig[];
  state: State<PerformableGroupState>;
  order: TenantOrderContext;
  form: { name: string; remove: () => void }[];

  add(config: TenantPerformableConfig, metadata?: Record<string, unknown>): void;

  remove(config: TenantPerformableConfig): void;
}

const context = createContext<PerformableGroupContext | null>(null);

const FORM_FIELD = 'performables';

function Inner({
  children,
  required,
  order,
  state,
}: {
  state: State<PerformableGroupState>;
  children: ReactNode;
  required: TenantPerformableConfig[];
  order: TenantOrderContext;
}) {
  const scoped = useHookstate(state);
  const [performables, addPerformable] = useZodFormFieldArray(FORM_FIELD);

  return (
    <context.Provider
      value={{
        required,
        order,
        state: scoped,
        form: performables,
        add(config, metadata) {
          // add to form state
          addPerformable({
            performable_id: config.id,
            metadata: metadata ?? {},
          });

          // update tuple in zod schema
          scoped.added.merge([config]);
        },
        remove(config) {
          const index = getPerformableStateIndex(this, config);

          if (index > -1) {
            // remove from form state
            performables[index].remove();

            // remove from zod schema
            scoped.added.set((prev) => prev.filter((p) => p !== config));
          }
        },
      }}
    >
      {children}
    </context.Provider>
  );
}

export function PerformableGroupProvider({
  children,
  required,
  order,
}: {
  children: ReactNode;
  order: TenantOrderContext;
  required: TenantPerformableConfig[];
}) {
  const tenant = useTenantWrapper();
  const state = useHookstate<PerformableGroupState>({
    revenue: new Big(0),
    added: [],
  });

  const combined = [...required, ...state.added.get({ noproxy: true })];

  const [first, ...remaining] = combined.map((performable) =>
    z.object({
      performable_id: z.literal(performable.id),
      metadata: performable.schema[FIRST_VERSION_TIMESTAMP],
    }),
  );

  const schema = z.object({
    [FORM_FIELD]: z.tuple([first, ...remaining]),
  });

  return (
    <ZodForm
      schema={schema}
      defaultValues={{
        [FORM_FIELD]: combined.map((p) => ({
          performable_id: p.id,
          metadata: tenant.job(p as TenantPerformableConfig, order).metadata,
        })),
      }}
      onValid={(data) => {
        const before = tenant.order(order).revenue();

        const after = tenant
          .order({
            ...order,
            jobs: [
              ...order.jobs,
              ...(data.performables as any),
            ],
          })
          .revenue();

        state.revenue.set(after.minus(before));
      }}
    >
      <Inner state={state} required={required} order={order}>
        {children}
      </Inner>
    </ZodForm>
  );
}

export function PerformableGroupRevenue() {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('PerformableGroupRevenue must be used within a PerformableGroupProvider');
  }

  const revenue = useHookstate(ctx.state).revenue.get();

  if (revenue) {
    return <>${revenue.toFixed(2)}</>;
  } else {
    return <>$0.00</>;
  }
}

export function usePerformableGroup<O extends TenantOrderContext>() {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('usePerformableGroup must be used within a PerformableGroupProvider');
  }

  return {
    order: ctx.order as O,

    // TODO: make metadata typed
    addPerformable<P extends TenantPerformableConfig>(config: P, metadata?: Record<string, unknown>) {
      if (ctx.state.added.get({ stealth: true }).includes(config)) {
        // should we support duplicates?
        throw new Error(`Performable ${config.id} already added to group.`);
      }

      // add performable to state
      ctx.add(config, metadata);
    },

    removePerformable(config: TenantPerformableConfig) {
      ctx.remove(config);
    },

    hasPerformable(config: TenantPerformableConfig) {
      return getPerformableStateIndex(ctx, config) >= 0;
    },
  };
}

export function usePerformableName(config: TenantPerformableConfig) {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('PerformableGroupForm must be used within a PerformableGroupProvider');
  }

  const index = getPerformableStateIndex(ctx, config);

  if (index === -1) {
    throw new Error(`Performable ${config.id} not found in group.`);
  }

  return ctx.form[index].name;
}

export function PerformableGroupNested({
  performable,
  children,
}: {
  performable: TenantPerformableConfig;
  children: ReactNode;
}) {
  const groupName = usePerformableName(performable);

  return <ZodNestedForm name={groupName}>{children}</ZodNestedForm>;
}

export default function PerformableGroupForm({
  performable,
  children,
}: {
  performable: TenantPerformableConfig;
  children?: ReactNode;
}) {
  return (
    <PerformableGroupNested performable={performable}>
      <ZodFieldHidden name="performable_id" value={performable.id} />

      <ZodNestedForm name="metadata">
        <ZodFieldHidden name="version" value={FIRST_VERSION_TIMESTAMP} />
        {children}
      </ZodNestedForm>
    </PerformableGroupNested>
  );
}

function getPerformableStateIndex(ctx: PerformableGroupContext, performable: TenantPerformableConfig) {
  const added = ctx.state.added.get({ stealth: true }).findIndex((c) => performable.id === c.id);

  return added > -1 ? added + ctx.required.length : ctx.required.indexOf(performable);
}
